import React from 'react';
import Application from 'navigation';

function App() {
  return (
     <>
     <Application />
    </>
  );
}

export default App;
