import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as AdminLoadable from "./loadRoutes/admin.load";
// import * as AuthLoadable from "./loadRoutes/auth.load";
import AppRoutes from "./appRoutes";

function Application() {
  return (
    <Router>
      <Routes>
        {/* <Route element={<AuthLoadable.Login />} path={AppRoutes.LOGIN} /> */}
        <Route
          element={<AdminLoadable.Dashboard />}
          path={AppRoutes.DASHBOARD}
        />
      </Routes>
    </Router>
  );
}

export default Application;
